// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const SITE_ADMIN = 'admin';
export const SITE_EVAL = 'eval';
export const IS_LOCAL = false;
export const MOCK_TOKEN =
  'eyJraWQiOiJvUnJhY0taRjdWdnczbzEyWWU4OG44QkIzeFpkZVRFTkxtMWRQdGJxVHZjIiwiYWxnIjoiUlMyNTYifQ.eyJncm91cHMiOiJQWExfQURNSU5BUFBfR1JPVVBFIiwicnRfaGFzaCI6Ii1MXzBmd1JlNk4zckZrZmE5Tkh5M3ciLCJub25jZSI6ImI4NTRmNzQwNmYxNjkzMTMzZTdhZDg4ZTQxMTUzYzYwNmRsaG01RFVMIiwiZW1haWxBZGRyZXNzIjoibW91aHNzaW5lLmtkaWRhckBleHQuYnBpZnJhbmNlLmZyIiwiaWF0IjoxNzIxMzkwOTU0LCJpc3MiOiJodHRwczovL2F1dGhtb2Eud2ViLmJwaWZyYW5jZS5mciIsImF0X2hhc2giOiJ5aVlkc3dCMl9halJwSllnX2dEbHFRIiwic3ViIjoibTE2MTY5IiwibGFzdE5hbWUiOiJrZGlkYXIiLCJtYXQiOiJNMTYxNjkiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJNMTYxNjkiLCJmaXJzdE5hbWUiOiJtb3Voc3NpbmUiLCJleHAiOjE3MjEzOTQ1NTQsImF1ZCI6IjVsSTJRam1tNFdzbEFJT1Q2NVk1In0.FNti8T2wUsZWIRPrUTPznf-Z0VULLjjBzzIrPNHxq7Sl0-A8wI8DQ3UMHmLxK14pEIH-8cgSMblchCf-t2sT7rJhO83oFOIs8T2mO3dEP1HLg8ywHq7iNJS_4nz1MrDZvj_feEKuE62kb_WYPrcz4RMtmB6Av0enR7SDvTSK2m0rEPBg29fhXZyI2jxUvsDAkpl6nHr7m7TdM7v26sPoVjqy9i7tpea3Ab7yOTmFKE9Sdp1t-RDfF4N1NXMdLekJc1qKEC3Db6joTJRvvyrHy41pR8HCqcKzcD5phDfZQAIoysX15QAi8rNeV8ADeCwxcx0fhPqmmgykOUKnCxmzwQ';
const LOCAL_API_BASE_URL = 'http://localhost:8083/api/';
const ISAM_DEV_API_BASE_URL = 'https://apiv2-nprd.cloud.bpifrance.fr/dev/pxl/interne/sig-dev/api/';
const LAMBDA_DEV_API_BASE_URL = 'https://pxl.dev.api-at.cloud.bpifrance.fr/main/v1/';
const getIsamBaseUrl: (isLambdaRequest?: boolean) => string = (isLambdaRequest?: boolean) => {
  if (isLambdaRequest) {
    return (window as any)['env']['apiUrl'] || (IS_LOCAL ? LOCAL_API_BASE_URL : LAMBDA_DEV_API_BASE_URL);
  }
  return (window as any)['env']['isamBaseApiUrl'] || (IS_LOCAL ? LOCAL_API_BASE_URL : ISAM_DEV_API_BASE_URL);
};

export const environment = {
  production: false,
  name: (window as any)['env']['name'] || 'local',
  isLocal: (window as any)['env']['isLocal'] || IS_LOCAL,
  mockToken: (window as any)['env']['mockToken'] || MOCK_TOKEN,
  apiSuiviUrl:
    (window as any)['env']['apiSuiviUrl'] ||
    (IS_LOCAL ? 'http://localhost:8085/api/' : 'https://apiv2-nprd.cloud.bpifrance.fr/dev/pxl/interne/suivi-dev/api/'),
  apiSearchUrl:
    (window as any)['env']['apiSearchUrl'] ||
    (IS_LOCAL ? 'http://localhost:8086/' : 'https://apiv2-nprd.cloud.bpifrance.fr/dev/pxl/search-dev/'),
  apiUrlLambda: getIsamBaseUrl(true),
  apiUrl: getIsamBaseUrl(),
  apiAdminUrl: `${getIsamBaseUrl()}admin/`,
  apiAdminUrlV2: `${getIsamBaseUrl()}v2/admin/`,
  apiNotif: 'http://localhost:8082/api/',
  urlGDC: (window as any)['env']['urlGDC'] || 'https://gdcoad-ihm.dev.apps.web.bpifrance.fr/gdc/#/dc',
  stsServer: (window as any)['env']['stsServer'] || 'https://authmoa.web.bpifrance.fr',
  authWellknownEndpoint:
    (window as any)['env']['authWellknownEndpoint'] || 'https://authmoa.web.bpifrance.fr/mga/sps/oauth/oauth20/metadata/OIDCP_All-Prof',
  clientId: (window as any)['env']['clientId'] || '5lI2Qjmm4WslAIOT65Y5',
  docPublicBaseUrl: (window as any)['env']['docPublicBaseUrl'] || 'https://evaluateur.dev.pxl-nprd.cloud.bpifrance.fr/document-public/',
  docLogosPublicBaseUrl: (window as any)['env']['docLogosPublicBaseUrl'] || 'https://candidat.dev.pxl-nprd.cloud.bpifrance.fr/logos/',
  espaceCandidat: (window as any)['env']['espaceCandidat'] || 'https://candidat.dev.pxl-nprd.cloud.bpifrance.fr/',
  espaceEval: (window as any)['env']['espaceEval'] || 'https://evaluateur.dev.pxl-nprd.cloud.bpifrance.fr/',
  site: (window as any)['env']['site'] || SITE_ADMIN,
  stub: false,
  stubPrograms: false,
  stubAaps: false,
  stubProjets: false,
  stubAuth: false,
  stubDoc: false,
  stubUtilisateur: false,
  urlQlik: 'https://master-staging.qls-nprd.cloud.bpifrance.fr/saml/hub/stream/73023daa-a9fb-450a-a2d6-ffbfe50325ec',
  urlKLF: 'https://kyc.moa.bpi.flaminem.com/customer-file/file/',
  youSignUiId: (window as any)['env']['youSignUiId'] || 'f49bc296-ae0c-4a23-bf4d-1e4d41e8b876',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
